export default [
  // ----------------------------------------------------------------------
  //    會員頁面:會員資料
  // ----------------------------------------------------------------------
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views/client/member/Member.vue'),
    meta: {
      pageTitle: '會員中心',
      breadcrumb: [
        {
          text: '會員中心',
          active: true,
        },
      ],
    },
  },
]
