import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import adminAPI from './api/admin'
import clientAPI from './api/client'
import publicAPI from './api/public'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    public: publicAPI,
    admin: adminAPI,
    client: clientAPI,
  },
  strict: process.env.DEV,
})
