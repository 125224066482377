import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getUserRoutes = () => JSON.parse(localStorage.getItem('userRoutes'))

export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'Admin') return { name: 'admin-home' }
  if (userRole === 'Client') return { name: 'member' }
  return { name: 'auth-login' }
}

export const setUserVerticalManu = resources => {
  if (resources === 'Admin') store.commit('verticalMenu/UPDATE_VERTICAL_MENU_DISPLAY', resources)
}

// export const redirectLastRouter = fromRouter => {
//   return { name: `${fromRouter}` }
// }

export const chkToken = () => {
  const token = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  return useJwt.refreshToken(token)
}

export const setToken = token => {
  useJwt.setToken(token)
  window.location.reload()
}

export const logout = () => {
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  store.commit('app/UPDATE_USERDATA_STATE', '')
  store.commit('verticalMenu/UPDATE_VERTICAL_MENU_DISPLAY', 'Client')
  localStorage.clear()
}
