import auth from './components/auth'

export default [
  ...auth,
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/pages/team/Team.vue'),
    meta: {
      pageTitle: '團隊介紹',
      breadcrumb: [
        {
          text: '團隊介紹',
          active: true,
        },
      ],
    },
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/pages/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
]
