// import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
}
