export default [
  // ----------------------------------------------------------------------
  //    員工管理
  // ----------------------------------------------------------------------
  {
    path: '/admin/personnel',
    name: 'admin-personnel-list',
    component: () => import('@/views/admin/personnel/personnel-list/PersonnelList.vue'),
    meta: {
      layout: 'vertical',
      ability: 'admin-personnel',
      resources: 'Admin',
      pageTitle: '員工列表',
      breadcrumb: [
        {
          text: '員工列表',
          active: true,
        },
      ],
    },
  },
]
