import member from './components/member'

export default [
  // ----------------------------------------------------------------------
  //    客戶頁面
  // ----------------------------------------------------------------------
  {
    path: '/home',
    name: 'client-home',
    component: () => import('@/views/client/home/Home.vue'),
    meta: {
    },
  },
  ...member,
]
