import personnel from './components/personnel'

export default [
  // ----------------------------------------------------------------------
  //    員工頁面:公司首頁/個人資料
  // ----------------------------------------------------------------------
  {
    path: '/admin/home',
    name: 'admin-home',
    component: () => import('@/views/admin/home/Home.vue'),
    meta: {
      layout: 'vertical',
      resources: 'Admin',
      ability: 'admin-home',
    },
  },
  {
    path: '/admin/profile',
    name: 'admin-profile',
    component: () => import('@/views/admin/profile/Profile.vue'),
    meta: {
      layout: 'vertical',
      ability: 'admin-profile',
      resources: 'Admin',
      pageTitle: '個人檔案',
      breadcrumb: [
        {
          text: '個人檔案',
          active: true,
        },
      ],
    },
  },
  ...personnel,
]
