import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faServer,
  faNetworkWired,
  faShieldAlt,
  faLifeRing,
  faHdd,
  faCloudUploadAlt,
  faCloud,
  faDesktop,
  faMobileAlt,
  faCircle,
  faHome,
  faUsers,
  faUser,
  faEnvelope,
  faPlusCircle,
  faDollarSign,
  faDownload,
  faConciergeBell,
  faAnchor,
  faIdCard,
  faFileSignature,
  faQuestionCircle,
  faCommentDots,
  faUserFriends,
  faPencilAlt,
  faLock,
  faEdit,
  faLaptopMedical,
  faProjectDiagram,
  faBell,
  faLaptopHouse,
  faDonate,
  faArrowCircleRight,
  faArrowRight,
  faDotCircle,
  faCircleNotch,
  faTv,
  faCommentsDollar,
  faAd,
  faPaste,
  faSave,
  faPlus,
  faStar,
  faCog,
  faPhone,
  faTag,
  faBriefcase,
  faImages,
  faTrashAlt,
  faClipboardList,
  faListUl,
  faUserShield,
  faFolderPlus,
  faKey,
  faCoins,
  faLink,
  faFemale,
  faCalendarAlt,
  faLandmark,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons' // fas

// import { } from '@fortawesome/free-regular-svg-icons' // far
// import {} from '@fortawesome/free-brands-svg-icons' // fab

library.add(
  faServer,
  faNetworkWired,
  faShieldAlt,
  faLifeRing,
  faHdd,
  faCloudUploadAlt,
  faCloud,
  faDesktop,
  faMobileAlt,
  faCircle,
  faHome,
  faUsers,
  faUser,
  faEnvelope,
  faPlusCircle,
  faDollarSign,
  faDownload,
  faConciergeBell,
  faAnchor,
  faIdCard,
  faFileSignature,
  faQuestionCircle,
  faCommentDots,
  faUserFriends,
  faPencilAlt,
  faLock,
  faEdit,
  faLaptopMedical,
  faProjectDiagram,
  faBell,
  faLaptopHouse,
  faDonate,
  faArrowCircleRight,
  faArrowRight,
  faDotCircle,
  faCircleNotch,
  faTv,
  faCommentsDollar,
  faAd,
  faPaste,
  faSave,
  faPlus,
  faStar,
  faCog,
  faPhone,
  faTag,
  faBriefcase,
  faImages,
  faTrashAlt,
  faClipboardList,
  faListUl,
  faUserShield,
  faFolderPlus,
  faKey,
  faCoins,
  faLink,
  faFemale,
  faCalendarAlt,
  faLandmark,
  faFolderOpen,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
