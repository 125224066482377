export default [
  // ----------------------------------------------------------------------
  //    身分頁面:錯誤/登入/註冊/忘記密碼
  // ----------------------------------------------------------------------
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  // ----------------------------------------------------------------------
  //    特殊情況頁面:敬請期待/未授權/維護中
  // ----------------------------------------------------------------------
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
    },
  },
  {
    path: '/under-maintenance',
    name: 'under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
    },
  },
  // ----------------------------------------------------------------------
  //    特殊頁面:激活註冊
  // ----------------------------------------------------------------------
  {
    path: '/activtion/register/:token',
    name: 'activtion',
    component: () => import('@/views/pages/activtion/ActivtionRegister.vue'),
    meta: {
      // redirectIfLoggedIn: true,
      layout: 'full',
    },
  },
  {
    path: '/activtion/forgotPW/:token',
    name: 'activtion-forgot-password',
    component: () => import('@/views/pages/activtion/ActivtionForgotPassword.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },

]
