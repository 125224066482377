import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: '/v1',
  // https://twhost.com.tw
})

Vue.prototype.$http = axiosIns

export default axiosIns
