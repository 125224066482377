import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  setUserVerticalManu, isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser,
} from '@/auth/utils'

import admin from './routes/admin/index'
import client from './routes/client/index'
import pages from './routes/pages/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'client-home' } },
    ...admin,
    ...client,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const userData = getUserData()

  // 瀏覽權限頁面時加載側欄種類
  if (to.matched.some(route => route.meta.resources)) setUserVerticalManu(to.meta.resources)

  // 已登入前往登入前頁面 ->導回預設頁面
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
